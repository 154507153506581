/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
export default new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/expertise",
      name: "Expertise",
      component: () => import("./views/Expertise.vue"),
    },
    {
      path: "/expertise/patent",
      name: "Patent",
      component: () => import("@/views/expertise/Patent.vue"),
    },
    {
      path: "/expertise/trademark",
      name: "Trademark",
      component: () => import("@/views/expertise/Trademark.vue"),
    },
    {
      path: "/expertise/industrial-design",
      name: "IndustrialDesign",
      component: () => import("@/views/expertise/IndustrialDesign.vue"),
    },
    {
      path: "/expertise/copyright",
      name: "Copyright",
      component: () => import("@/views/expertise/Copyright.vue"),
    },
    {
      path: "/our-people",
      name: "OurPeople",
      component: () => import("./views/OurPeople.vue"),
    },
    {
      path: "/about",
      name: "About",
      component: () => import("./views/About.vue"),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  // scrollBehavior() {
  //   document
  //     .getElementById("app")
  //     .scrollIntoView({ top: 0, behavior: "smooth" });
  // },
});
