import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  // translate untuk english
  en: {
    welcome_msg:
      "With the support from our experienced IPR Consultants, Lawyers and the expert staffs in IPR, We provides high-quality services to fulfill our clients needs in this globalization era.",
    learn_more: "LEARN MORE",
    learn_more_lowercase: "Learn more",
    cases: "Cases",
    clients: "Clients",
    yoe: "Years of Experience",
    countries: "Countries",
    patent: "PATENT",
    trademarks: "TRADEMARKS",
    industrial_design: "INDUSTRIAL DESIGN",
    copyright: "COPYRIGHT",
    services: "SERVICES",
    home: "Home",
    expertise_uppercase: "EXPERTISE",
    expertise: "Expertise",
    expertise_1: "Patent",
    expertise_2: "Trademarks",
    expertise_3: "Industrial Design",
    expertise_4: "Copyright",
    expertise_content:
      "The majority of our professional team have real-world experience across science, engineering, legal, defence, telecommunications, ICT, electronics, computing and finance. With a strong working knowledge of the markets and the industries in which our clients operate, they are able to provide more than just advice and insights on the legal aspects of IP.",
    our_people: "Our People",
    our_team: "OUR TEAM",
    our_partners: "OUR PARTNERS",
    about: "About",
    contact_us: "Contact Us",
    company_highlight:
      "is one of a prominent intellectual property firm in Indonesia.",
    patent_1:
      "A patent is an exclusive right granted by the state to an inventor for his invention in the field of technology for a certain period of time to carry out the invention himself or to give approval to another party to implement it.",
    patent_2_bold: "PATENT",
    patent_2:
      "Granted for an Invention that is new, contains an inventive step, and can be applied in industry.",
    patent_3_bold: "SIMPLE PATENT",
    patent_3:
      "Granted for any new Invention, development of an existing product or process, and can be applied in industry.",
    patent_4_bold: "PATENT PROTECTION TERM",
    patent_4_line_1:
      "Patent is granted for a period of 20 (twenty) years from the Filing Date and cannot be renewed.",
    patent_4_line_2:
      "Simple patent is granted for a period of 10 (ten) years from the date of filing and cannot be renewed.",
    patent_4_bold_2: "PATENT REGISTRATION FLOW",
    patent_4_bold_3: "SIMPLE PATENT REGISTRATION FLOW",
    trademark_1:
      "A mark is a sign that can be displayed graphically in the form of an image, logo, name, word, letter, number, color arrangement, in the form of 2 (two) dimensions and/or 3 (three) dimensions, sound, hologram, or a combination of 2 (two) dimensions) or more of these elements to distinguish goods and/or services produced by persons or legal entities in the activities of trading goods and/or services.",
    trademark_2_bold: "A mark cannot be registered if :",
    trademark_2_line_1:
      "contrary to state ideology, legislation, morality, religion, decency, or public order;",
    trademark_2_line_2:
      "the same as, relating to, or only mentioning the goods and/or services for which registration is requested;",
    trademark_2_line_3:
      "contains elements that can mislead the public about the origin, quality, type, size, type, purpose of use of goods and/or services for which registration is requested or is the name of a protected plant variety for similar goods and/or services;",
    trademark_2_line_4:
      "contains information that is not in accordance with the quality, benefits, or efficacy of the goods and/or services produced;",
    trademark_2_line_5: "have no differentiators; and/or",
    trademark_2_line_6: "is a common name and/or symbol of public property.",
    trademark_3_bold: "Brand Rejected Application:",
    trademark_3_line_1:
      "The application is rejected if the Mark has similarities in principle or in its entirety with:",
    trademark_3_line_2:
      "A registered mark belonging to another party or previously requested by another party for similar goods and/or services;",
    trademark_3_line_3:
      "Well-known mark belonging to other parties for similar goods and/or services;",
    trademark_3_line_4:
      "Well-known mark belonging to other parties for goods and/or services of a different kind that meet certain requirements; or",
    trademark_3_line_5: "Registered Geographical Indications.",
    trademark_4_bold: "The application is rejected if the Mark:",
    trademark_4_line_1:
      "constitutes or resembles the name or abbreviation of the name of a famous person, photo, or name of a legal entity owned by another person, except with the written consent of the entitled party;",
    trademark_4_line_2:
      "is an imitation or resembling the name or abbreviation of the name, flag, symbol or symbol or emblem of a country, or national or international institution, except with written approval from the competent authority; or",
    trademark_4_line_3:
      "is an imitation or resembles an official sign or stamp or seal used by a state or government agency, except with the written approval of the competent authority.",
    trademark_5_bold:
      "An application is rejected if it is submitted by an applicant with bad faith.",
    trademark_6_bold: "Mark Protection Period",
    trademark_6_line_1:
      "A registered mark is protected for a period of 10 (ten) years from the Filing Date and can be renewed every 10 years.",
    trademark_6_line_2:
      "You can renew a trade mark in the 6 months before it expires and up to 6 months afterwards.",
    trademark_7_bold: "TRADEMARK REGISTRATION FLOW",
    design_line_1:
      "Industrial Design is a creation about the shape, configuration, or composition of lines or colors, or lines and colors, or a combination thereof in the form of three or two dimensions which gives an aesthetic impression and can be realized in three-dimensional or two-dimensional patterns and can be used to produce a product, goods, industrial commodity, or handicraft.",
    design_bold: "Industrial Design Protection Period",
    design_line_2:
      "Industrial Design registration is valid for 10 years from the filing date and cannot be renewed.",
    design_bold_2: "INDUSTRIAL DESIGN REGISTRATION FLOW",
    copyright_1:
      "Copyright is the exclusive right of the creator that arises automatically based on declarative principles after a work is realized in a tangible form without reducing restrictions in accordance with the provisions of laws and regulations.",
    copyright_bold: "Protected works include :",
    copyright_line_2:
      "books, pamphlets, representations of published works, and all other written works:",
    copyright_line_3:
      "lectures, lectures, speeches, and other similar creations;",
    copyright_line_4:
      "teaching aids made for the benefit of education and science;",
    copyright_line_5: "songs and/or music with or without subtitles;",
    copyright_line_6:
      "drama, musical drama, dance, choreography, wayang, and mime;",
    copyright_line_7:
      "works of art in all forms such as painting, drawing, carving, calligraphy, sculpture, sculpture, or collage;",
    copyright_line_8: "applied art;",
    copyright_line_9: "architectural works;",
    copyright_line_10: "map;",
    copyright_line_11: "batik art or other motif art;",
    copyright_line_12: "photographic works;",
    copyright_line_13: "portrait;",
    copyright_line_14: "cinematograph works;",
    copyright_line_15:
      "translation, interpretation, adaptation, anthology, database, adaptation, arrangement, modification and other works resulting from the transformation;",
    copyright_line_16:
      "translation, adaptation, arrangement, transformation, or modification of traditional cultural expressions;",
    copyright_line_17:
      "compilation of Works or data, either in a format that can be read with a Computer Program or other media;",
    copyright_line_18:
      "a compilation of traditional cultural expressions as long as the compilation is an original work;",
    copyright_line_19: "video games; and",
    copyright_line_20: "Computer program.",
    copyright_bold_2: "Copyright Protection Term",
    copyright_2_line_1:
      "Copyright Protection : Lifetime of Creator + 70 Years.",
    copyright_2_line_2:
      "Computer Program: 50 years Since it was first published.",
    copyright_2_line_3: "Actors: 50 years since the first show.",
    copyright_2_line_4:
      "Producer of Records : 50 years since the Creation is fixed.",
    copyright_2_line_5:
      "Broadcasting Institution: 20 years since it was first broadcast.",
    copyright_2_line_6:
      "It is regulated in Articles 58, 59, 60 and 63 of Law no. 28 of 2014 concerning Copyright",
    copyright_bold_3: "COPYRIGHT REGISTRATION FLOW",
    about_us: "ABOUT US",
    about_us_1: "Established in 2006",
    about_us_2:
      "is one of a prominent intellectual property firm in Indonesia. We are dedicated to providing comprehensive services in Intellectual Property Rights (IPR) for domestic and overseas clients. As a growing firm, we have a good reputaion for our capabilites and professionalism.",
    about_us_3:
      "serves entire aspects of IPR field by focusing itself on IPR protection, prosecution and filing IPR applications at the Directorate General of Intellectual Property Rights at the Republic of Indonesia. Our firm handles patent applications and prosecution as well as the annuity payment of granted patents, including trademark registrations, industrial designs and copyrights. In addition, we also handle the litigation on IPR cases.",
    about_us_4:
      "We continously update and improve our supporting facilities, mainly the computerized system of our database including both domestic and overseas on-line access.",
    vision: "Vision",
    vision_line_1:
      "Your idea is your most valuable asset in a global business community Intellectual property right is all about respect and protecting ideas.",
    mission: "Mission",
    mission_line_1:
      "Our reliable service in intellectual property will protect your ideas, the most valuable asset for your business growth. You only need to think and create, while the protection is our concern.",
  },

  // translate untuk indonesia
  id: {
    welcome_msg:
      "Dengan dukungan dari Konsultan HKI kami yang berpengalaman, Pengacara dan staf ahli di bidang HKI, Kami menyediakan layanan berkualitas tinggi untuk memenuhi kebutuhan klien kami di era globalisasi ini.",
    learn_more: "PELAJARI SELENGKAPNYA",
    learn_more_lowercase: "Pelajari selengkapnya",
    cases: "Kasus",
    clients: "Klien",
    yoe: "Tahun Pengalaman",
    countries: "Negara",
    patent: "PATEN",
    trademarks: "MEREK",
    industrial_design: "DESAIN INDUSTRI",
    copyright: "HAK CIPTA",
    services: "SERVIS",
    home: "Beranda",
    expertise_uppercase: "LAYANAN",
    expertise: "Layanan",
    expertise_1: "Paten",
    expertise_2: "Merek",
    expertise_3: "Desain Industri",
    expertise_4: "Hak Cipta",
    expertise_content:
      "Mayoritas tim profesional kami memiliki pengalaman dunia nyata di bidang sains, teknik, hukum, pertahanan, telekomunikasi, TIK, elektronik, komputasi, dan keuangan. Dengan pengetahuan kerja yang kuat tentang pasar dan industri tempat klien kami beroperasi, mereka mampu memberikan lebih dari sekadar saran dan wawasan tentang aspek hukum IP.",
    our_people: "Tim Kami",
    our_team: "TIM KAMI",
    our_partners: "PARTNER KAMI",
    about: "Tentang",
    contact_us: "Hubungi Kami",
    company_highlight:
      "adalah salah satu perusahaan kekayaan intelektual terkemuka di Indonesia.",
    patent_1:
      "Paten adalah hak eksklusif yang diberikan oleh negara  kepada inventor atas hasil invensinya di bidang teknologi  untuk jangka waktu tertentu melaksanakan sendiri  invensi tersebut atau memberikan persetujuan kepada  pihak lain untuk melaksanakannya.",
    patent_2_bold: "PATEN BIASA",
    patent_2:
      "Diberikan untuk Invensi yang baru, mengandung langkah inventif, dan dapat diterapkan dalam industri.",
    patent_3_bold: "PATEN SEDERHANA",
    patent_3:
      "Diberikan untuk setiap Invensi baru, pengembangan dari  produk atau proses yang telah ada, dan dapat  diterapkan dalam industri.",
    patent_4_bold: "JANGKA WAKTU PERLINDUNGAN PATEN",
    patent_4_line_1:
      "Paten diberikan untuk jangka waktu 20 (dua puluh) tahun terhitung sejak Tanggal Penerimaan. ",
    patent_4_line_2:
      "Paten sederhana diberikan untuk jangka waktu 10 (sepuluh) tahun terhitung sejak Tanggal penerimaan.",
    patent_4_bold_2: "ALUR PENDAFTARAN PATEN",
    patent_4_bold_3: "ALUR PENDAFTARAN PATEN SEDERHANA",
    trademark_1:
      "Merek adalah tanda yang dapat ditampilkan secara  grafis berupa gambar, logo, nama, kata, huruf, angka,  susunan warna, dalam bentuk 2 (dua) dimensi dan/atau 3  (tiga) dimensi, suara, hologram, atau kombinasi dari 2  (dua) atau lebih unsur tersebut untuk membedakan  barang dan/atau jasa yang diproduksi oleh orang atau  badan hukum dalam kegiatan perdagangan barang  dan/atau jasa.",
    trademark_2_bold: "MEREK TIDAK DAPAT DIDAFTAR JIKA:",
    trademark_2_line_1:
      "bertentangan dengan ideologi negara, peraturan perundang-undangan, moralitas, agama, kesusilaan, atau ketertiban umum;",
    trademark_2_line_2:
      "sama dengan, berkaitan dengan, atau hanya menyebut barang dan/atau jasa  yang dimohonkan pendaftarannya;",
    trademark_2_line_3:
      "memuat unsur yang dapat menyesatkan masyarakat tentang asal, kualitas, jenis,  ukuran, macam, tujuan penggunaan barang dan/atau jasa yang dimohonkan  pendaftarannya atau merupakan nama varietas tanaman yang dilindungi untuk  barang dan/atau jasa yang sejenis;",
    trademark_2_line_4:
      "memuat keterangan yang tidak sesuai dengan kualitas, manfaat, atau khasiat dari  barang dan/atau jasa yang diproduksi;",
    trademark_2_line_5: "tidak memiliki daya pembeda; dan/atau",
    trademark_2_line_6: "merupakan nama umum dan/atau lambang milik umum.",
    trademark_3_bold: "MEREK YANG PERMOHONANNYA DITOLAK:",
    trademark_3_line_1:
      "Permohonan ditolak jika Merek tersebut mempunyai persamaan pada pokoknya atau keseluruhannya dengan:",
    trademark_3_line_2:
      "Merek terdaftar milik pihak lain atau dimohonkan lebih dahulu oleh pihak lain untuk barang dan/atau jasa sejenis;",
    trademark_3_line_3:
      "Merek terkenal milik pihak lain untuk barang dan/atau jasa sejenis;",
    trademark_3_line_4:
      "Merek terkenal milik pihak lain untuk barang dan/atau jasa tidak sejenis yang memenuhi persyaratan tertentu; atau",
    trademark_3_line_5: "Indikasi Geografis terdaftar.",
    trademark_4_bold: "Permohonan ditolak jika Merek tersebut:",
    trademark_4_line_1:
      "merupakan atau menyerupai nama atau singkatan nama orang terkenal, foto, atau nama badan hukum yang dimiliki orang lain, kecuali atas persetujuan tertulis dari yang berhak;",
    trademark_4_line_2:
      "merupakan tiruan atau menyerupai nama atau singkatan nama, bendera, lambang atau simbol atau emblem suatu  negara, atau lembaga nasional maupun internasional, kecuali atas persetujuan tertulis dari pihak yang berwenang;  atau",
    trademark_4_line_3:
      "merupakan tiruan atau menyerupai tanda atau cap atau stempel resmi yang digunakan oleh negara atau lembaga  Pemerintah, kecuali atas persetujuan tertulis dari pihak yang berwenang.",
    trademark_5_bold:
      "Permohonan ditolak jika diajukan oleh Pemohon yang beriktikad tidak baik.",
    trademark_6_bold: "Jangka Waktu Perlindungan Merek",
    trademark_6_line_1:
      "Merek terdaftar mendapat pelindungan hukum untuk  jangka waktu 10 (sepuluh) tahun sejak Tanggal  Penerimaan.",
    trademark_6_line_2:
      "Anda dapat memperbarui merek dagang dalam 6 bulan sebelum kedaluwarsa dan hingga 6 bulan setelahnya.",
    trademark_7_bold: "ALUR PENDAFTARAN MEREK",
    design_line_1:
      "Desain Industri adalah suatu kreasi tentang bentuk, konfigurasi, atau komposisi garis atau warna,  atau garis dan warna, atau gabungan daripadanya yang berbentuk tiga  dimensi atau dua dimensi yang memberikan kesan estetis dan dapat  diwujudkan dalam pola tiga dimensi atau dua dimensi serta dapat  dipakai untuk menghasilkan suatu produk, barang, komoditas industri,  atau kerajinan tangan.",
    design_bold: "Jangka Waktu Perlindungan Desain Industri",
    design_line_2:
      "Perlindungan terhadap Hak Desain Industri diberikan  untuk jangka waktu 10 (sepuluh) tahun terhitung sejak  Tanggal Penerimaan.",
    design_bold_2: "ALUR PENDAFTARAN DESAIN INDUSTRI",
    copyright_1:
      "Hak Cipta adalah hak eksklusif pencipta yang timbul secara otomatis berdasarkan prinsip deklaratif setelah suatu ciptaan diwujudkan dalam bentuk nyata tanpa  mengurangi pembatasan sesuai dengan ketentuan peraturan perundang-undangan.",
    copyright_bold: "Ciptaan yang dilindungi meliputi :",
    copyright_line_2:
      "buku, pamflet, perwajahan karya tulis yang diterbitkan, dan semua hasil karya tulis lainnya:",
    copyright_line_3: "ceramah, kuliah, pidato, dan Ciptaan sejenis lainnya;",
    copyright_line_4:
      "alat peraga yang dibuat untuk kepentingan pendidikan dan ilmu pengetahuan;",
    copyright_line_5: "lagu dan/atau musik dengan atau tanpa teks;",
    copyright_line_6:
      "drama, drama musikal, tari, koreografi, pewayangan, dan pantomim;",
    copyright_line_7:
      "karya seni rupa dalam segala bentuk seperti lukisan, gambar, ukiran, kaligrafi, seni pahat, patung, atau kolase;",
    copyright_line_8: "karya seni terapan;",
    copyright_line_9: "karya arsitektur;",
    copyright_line_10: "peta;",
    copyright_line_11: "karya seni batik atau seni motif lain;",
    copyright_line_12: "karya fotografi;",
    copyright_line_13: "potret;",
    copyright_line_14: "karya sinematografi;",
    copyright_line_15:
      "terjemahan, tafsir, saduran, bunga rampai, basis data, adaptasi, aransemen, modifikasi dan karya  lain dari hasil transformasi;",
    copyright_line_16:
      "terjemahan, adaptasi, aransemen, transformasi, atau modihkasi ekspresi budaya tradisional;",
    copyright_line_17:
      "kompilasi Ciptaan atau data, baik dalam format yang dapat dibaca dengan Program Komputer  maupun media lainnya;",
    copyright_line_18:
      "kompilasi ekspresi budaya tradisional selama kompilasi tersebut merupakan karya yang asli;",
    copyright_line_19: "permainan video; dan",
    copyright_line_20: "program komputer",
    copyright_bold_2: "Jangka Waktu Perlindungan Hak Cipta",
    copyright_2_line_1:
      "Perlindungan Hak Cipta : Seumur Hidup Pencipta + 70 Tahun.",
    copyright_2_line_2:
      "Program Komputer : 50 tahun Sejak pertama kali dipublikasikan.",
    copyright_2_line_3: "Pelaku : 50 tahun sejak pertama kali di pertunjukkan.",
    copyright_2_line_4:
      "Produser Rekaman : 50 tahun sejak Ciptaan di fiksasikan.",
    copyright_2_line_5:
      "Lembaga Penyiaran : 20 tahun sejak pertama kali di siarkan.",
    copyright_2_line_6:
      "Diatur pada Pasal 58, 59, 60 dan 63 UU No. 28 Tahun 2014  tentang Hak Cipta",
    copyright_bold_3: "ALUR PENDAFTARAN HAK CIPTA",
    about_us: "TENTANG KAMI",
    about_us_1: "Didirikan pada tahun 2006",
    about_us_2:
      "adalah salah satu perusahaan kekayaan intelektual terkemuka di Indonesia. Kami berdedikasi untuk menyediakan layanan yang komprehensif dalam Hak Kekayaan Intelektual (HAKI) untuk klien domestik dan luar negeri. Sebagai perusahaan yang sedang berkembang, kami memiliki reputasi yang baik untuk kapabilitas dan profesionalisme kami.",
    about_us_3:
      "melayani seluruh aspek bidang HKI dengan memfokuskan diri pada perlindungan, penuntutan dan pengajuan permohonan HKI pada Direktorat Jenderal Hak Kekayaan Intelektual Republik Indonesia. Perusahaan kami menangani aplikasi paten dan penuntutan serta pembayaran anuitas paten yang diberikan, termasuk pendaftaran merek dagang, desain industri dan hak cipta. Selain itu, kami juga menangani litigasi kasus HKI.",
    about_us_4:
      "Kami terus memperbarui dan meningkatkan fasilitas pendukung kami, terutama sistem komputerisasi database kami termasuk akses on-line domestik dan luar negeri.",
    vision: "Visi",
    vision_line_1:
      "Ide Anda adalah aset Anda yang paling berharga dalam komunitas bisnis global Hak kekayaan intelektual adalah tentang menghormati dan melindungi ide.",
    mission: "Misi",
    mission_line_1:
      "Layanan kami yang andal dalam kekayaan intelektual akan melindungi ide-ide Anda, aset paling berharga untuk pertumbuhan bisnis Anda. Anda hanya perlu berpikir dan berkreasi, sedangkan perlindungan menjadi perhatian kami.",
  },
};

const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "id", // set fallback locale
  messages, // set locale messages
});

export default i18n;
